<template>
  <div class="contentTitle">
    <span @click="goBack"
      ><h3 class="pointer">
        <img src="/assets/images/left-arrow.svg" /> Inbox
      </h3></span
    >
  </div>
  <div class="contentMenu"></div>
  <div class="ticket-wrapper inbox-message-wrapper">
    <div class="ticket-left">
      <div class="ticket-left-top"></div>
      <div class="ticket-listing">
        <span
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab"
          :class="{ active: isActive === index }"
          @click="isActive = index"
          >{{ tab }}</span
        >
      </div>
    </div>
    <div class="ticket-right">
      <div class="ticket-left-top">
        <div class="user-details">
          <figure>
            <template v-if="ticketDetails.ticket_raised?.avatar">
              <img :src="imgURL + ticketDetails.ticket_raised.avatar" alt="" />
            </template>
            <template v-else>
              <span>{{ ticketDetails.ticket_raised?.name.charAt(0) }}</span>
            </template>
          </figure>
          <div v-if="ticketDetails" class="ticket-info">
            <span class="ticket-name"
              >{{ ticketDetails.title }} #{{ ticketDetails.id }}</span
            >
            <span class="ticket-desc">
              {{
                ticketDetails.ticket_raised
                  ? ticketDetails.ticket_raised.name
                  : ""
              }}
              <span>
                |
                {{
                  ticketDetails.client_name ? ticketDetails.client_name : ""
                }}</span
              >
              <span class="date"> • created {{ timeSince }} </span>
            </span>
          </div>
        </div>
        <div class="right-corner">
          <span v-if="isActive === 0 && userBelongsToChat"
            ><img src="/assets/images/phone-icon.png" alt=""
          /></span>
          <span
            class="close-tckt"
            v-if="canCreate.includes(getUserProfile.role.id)"
            @click="handleCloseTicket"
            >Close Ticket</span
          >
          <div
            class="priorities"
            v-if="canCreate.includes(getUserProfile.role.id)"
          >
            <div class="current" @click="openPriorities = true">
              <div class="prio">
                <template v-if="!Object.keys(selectedPriority).length">
                  <img :src="priority.icon" alt="" />
                  <span :class="priority.name">{{ priority.name }}</span>
                </template>
                <template v-else>
                  <img :src="selectedPriority.icon" alt="" />
                  <span :class="selectedPriority.name">{{
                    selectedPriority.name
                  }}</span>
                </template>
              </div>
              <div
                class="arrow"
                v-if="canCreate.includes(getUserProfile.role.id)"
              >
                <img src="/assets/images/left-arrow-grey.svg" />
              </div>
            </div>
            <transition name="fadeHeight" mode="out-in">
              <div
                v-if="
                  openPriorities && canCreate.includes(getUserProfile.role.id)
                "
                ref="prios"
                class="toChoose"
                :class="{
                  openPriority: !closePrios,
                  closePriority: closePrios,
                }"
              >
                <div
                  class="priority-option"
                  v-for="(prio, index) in priorities"
                  :key="index"
                  @click="handlePrio(prio)"
                >
                  <img :src="prio.icon" />
                  <span :class="prio.name">{{ prio.name }}</span>
                  <img
                    class="arrow"
                    v-if="prio.id === undefined"
                    src="/assets/images/left-arrow-grey.svg"
                  />
                </div>
              </div>
            </transition>
          </div>
            <figure class="options" @click="edit = !edit">
                <img  src="/assets/images/black_dots.svg" alt="options" />
            </figure>
        </div>
      </div>
      <div class="templates-container">
        <messages v-if="isActive === 0" :details="ticketDetails" :edit="edit" />
        <assign
          v-if="isActive === 1"
          :details="ticketDetails"
          :canEdit="canCreate.includes(getUserProfile.role.id)"
          @updateData="getTicketDetails"
        />
        <product-details v-if="isActive === 2" :details="ticketDetails" />
        <Details v-if="isActive === 3" :details="ticketDetails" />
        <Documents v-else-if="isActive === 4" :details="ticketDetails" />
        <Guides v-else-if="isActive === 5" :details="ticketDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import { getCurrentInstance } from "vue";
import { mapActions, mapGetters } from "vuex";
import Messages from "./Messages.vue";
import Assign from "./Assign.vue";
import Details from "./Details.vue";
import Documents from "./Documents.vue";
import Guides from "./Guides.vue";
import ProductDetails from "./ProductDetails.vue";
import {
  GET_USER_PROFILE_GETTER,
  CHANGE_TICKET_PRIORITY_ACTION,
} from "@/store/storeconstants";
export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Remote Support History",
      tabs: ["Messages", "Assignees", "Product Details", "Ticket Details", "Documents", "Guides"],
      isActive: 0,
      ticketId: "",
      priorityStatus: "",
      priorityFlag: undefined,
      ticketDetails: [],
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
      priorities: [
        {
          id: undefined,
          icon: "/assets/images/nan_flag.svg",
          name: "chose priority",
        },
        {
          id: 1,
          icon: "/assets/images/low_flag.svg",
          name: "low",
        },
        {
          id: 2,
          icon: "/assets/images/medium_flag.svg",
          name: "medium",
        },
        {
          id: 3,
          icon: "/assets/images/high_flag.svg",
          name: "high",
        },
      ],
      selectedPriority: {},
      priority: {},
      openPriorities: false,
      closePrios: false,
      canCreate: [4, 5, 6, 7, 8],
      userBelongsToChat: false,
      edit: false,
    };
  },
  components: {
    Messages,
    Assign,
    Details,
    ProductDetails,
    Documents,
    Guides
  },
  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    timeSince() {
      const last_updated = Date.parse(this.ticketDetails.updated_at);
      const seconds = Math.floor((new Date() - last_updated) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return `at ${this.ticketDetails.updated_at.split("T")[0]}`;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return `at ${this.ticketDetails.updated_at.split("T")[0]}`;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        if (Math.floor(interval) > 5)
          return `at ${this.ticketDetails.updated_at.split("T")[0]}`;
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "day ago" : "days ago"
        }`;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "hour ago" : "hours ago"
        }`;
      }
      interval = seconds / 60;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "minute ago" : "minutes ago"
        }`;
      }
      return `${Math.floor(seconds)} ${
        Math.floor(seconds) === 1 ? "second ago" : "seconds ago"
      }`;
    },
  },
  methods: {
    ...mapActions("ticket", {
      changeTicketPriority: CHANGE_TICKET_PRIORITY_ACTION,
    }),
    async getTicketDetails() {
      let orgDataString = localStorage.getItem("organization");
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        const response = await axiosInstance.get(
          `ticket/${this.ticketId}?org_id=${orgData.id}`
        );
        this.ticketDetails = response.data.data;

        let icon;
        let name;
        let id = this.ticketDetails.ticket_priority.priority_details.id;

        switch (this.ticketDetails.ticket_priority.priority_details.id) {
          case 1:
            icon = "/assets/images/low_flag.svg";
            name = "low";
            break;
          case 2:
            icon = "/assets/images/medium_flag.svg";
            name = "medium";
            break;
          case 3:
            icon = "/assets/images/high_flag.svg";
            name = "high";
            break;

          default:
            icon = "/assets/images/nan_flag.svg";
            name = "set priority";
            break;
        }

        this.priority = { id, icon, name };
      }
    },
    async handlePrio(prio) {
      if (this.priority.id !== prio.id && prio.id !== undefined) {
        this.selectedPriority = prio;
        this.openPriorities = false;
        this.closePrios = false;
        await this.changeTicketPriority({
          ticket_id: this.ticketDetails.id,
          status: prio.id,
        }).then(() => {
          this.getTicketDetails();
        });
      } else {
        this.closePrios = true;
        setTimeout(() => {
          (this.openPriorities = false), (this.closePrios = false);
        }, 25);
      }
    },

    async handleCloseTicket() {
      const data = {
        ticket_id: this.ticketDetails.id,
        status_id: 5,
      };
      const response = await axiosInstance.post(`change-ticket-status`, data);

      if (response) this.$router.push("/remote-support/tickets");
    },

    goBack() {
      this.$router.go(-1);
      //this.$router.push({path : `/remote-support/tickets`})
    },
  },
  created() {
    this.ticketId = this.$route.params.ticketid;
    this.getTicketDetails();
  },
};
</script>

<style lang="scss" scoped>
.options {
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
}

.pointer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  img {
    width: 15px;
    height: 15px;
  }
}

.ticket-wrapper.inbox-message-wrapper {
  height: 75vh;
    @media only screen and (max-width: 1200px) {
        height: 65vh;
        display: flex;
        flex-direction: column;
    }

    .ticket-left {
       
        @media only screen and (max-width: 1200px) {
             max-width: unset;
             border-top-right-radius: 5px;
             border: none;
             flex: 0;
        }
        .ticket-left-top {
            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }

        .ticket-listing {
            
            @media only screen and (max-width: 1200px) {
                display: flex;
                flex-direction: row;
                overflow: auto;
                border-bottom: solid 1px $grey;
            }

            &::-webkit-scrollbar {
                @media only screen and (max-width: 1200px) {
                    display: none;
                }
            }

            .tab {
                display: flex;
                justify-content: center;
                align-items: center;

                @media only screen and (max-width: 1200px) {
                    border-bottom: solid 2px;
                    border-bottom-color: transparent;
                    padding: 10px 30px;
                }

                &.active {
                    @media only screen and (max-width: 1200px) {
                        background: white;
                        border-bottom-color: var(--primary-color);
                    }
                }
            }
        }
        
    }

    .ticket-right {
        @media only screen and (max-width: 1200px) {
            height: 90%;
        }
        .ticket-left-top {
            .right-corner {
                @media only screen and (max-width: 1200px) {
                    display: none;
                }
            }
        }
    }
}

.ticket-wrapper.inbox-message-wrapper .ticket-right {
  height: 100%;
}
.ticket-wrapper.inbox-message-wrapper .ticket-left-top {
  height: 8%;
  padding: 0 40px;
}
.ticket-wrapper.inbox-message-wrapper .templates-container {
  height: 100%;
  overflow: auto;
}
.ticket-listing {
  display: flex;
  flex-direction: column;
}
.ticket-listing .tab {
  padding: 30px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.1s;
}
.ticket-listing .tab.active {
  font-weight: 600;
  color: var(--primary-color);
  background: #f2f5f8;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top {
  display: flex;
  justify-content: space-between;
}
.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details {
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
}
.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details figure {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.ticket-wrapper.inbox-message-wrapper
  .ticket-left-top
  .user-details
  figure
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ticket-wrapper.inbox-message-wrapper
  .ticket-left-top
  .user-details
  figure
  span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  color: white;
}

.ticket-wrapper.inbox-message-wrapper
  .ticket-left-top
  .user-details
  .ticket-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ticket-wrapper.inbox-message-wrapper
  .ticket-left-top
  .user-details
  .ticket-info
  .ticket-desc {
  font-size: 0.8rem;
}
.ticket-wrapper.inbox-message-wrapper
  .ticket-left-top
  .user-details
  .ticket-info
  .ticket-desc
  .date {
  color: #cad6e2;
  font-weight: 500;
}

.ticket-left-top {
  .right-corner {
    display: flex;
    align-items: center;
    gap: 20px;
    .close-tckt {
      cursor: pointer;
      user-select: none;
      font-weight: 400;
      padding: 5px 20px;
      color: #ed0423;
      border-radius: 30px;
      border: solid 1px #ed0423;
      transition: 0.2s;
      &:hover {
        border: solid 1px transparent;
        background: #ed0423;
        color: white;
      }
    }
  }
}

.priorities {
  text-transform: capitalize;
  font-weight: 400;
  position: relative;
  display: flex;
  cursor: pointer;
}
.priorities .current {
  display: flex;
  gap: 5px;
}
.priorities .current .prio {
  display: flex;
  gap: 5px;
}
.priorities .current .arrow {
  width: 10px;
  transform: rotate(270deg);
}

.priorities span {
  color: #cad6e2;
  font-size: 0.8rem;
}
.priorities .medium {
  color: #fb9a4b;
}
.priorities .high {
  color: #ed0423;
}

.priorities .toChoose {
  position: absolute;
  right: 0;
  width: 150px;
  box-shadow: 0px 1px 6px #cad6e280;
  border-radius: 5px;
  overflow: hidden;
  z-index: 3;
  background: white;
}
.priorities .toChoose.openPriority {
  animation: open 0.2s linear forwards;
}
.priorities .toChoose.closePriority {
  animation: close 0.2s linear forwards;
}
.priorities .toChoose .priority-option {
  height: 2rem;
  padding: 0 10px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #cad6e280;
  user-select: none;
}
.priorities .toChoose .priority-option .arrow {
  width: 10px;
  transform: rotate(90deg);
}
.priorities .toChoose .priority-option:last-of-type {
  border: none;
}
.priorities .toChoose .priority-option:hover {
  background: #cad6e280;
}

@keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 250px;
  }
}
@keyframes close {
  from {
    max-height: 250px;
  }
  to {
    max-height: 0px;
  }
}
</style>